import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from '../src/router/index'
import moment from 'moment';
import CKEditor from "ckeditor4-vue"
Vue.prototype.$moment = moment;
Vue.use(VueRouter);
Vue.use(CKEditor);

import axios from 'axios';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // Set the baseURL
  axios.defaults.baseURL = process.env.API_BASE_URL;

  // Set the accessToken in the request headers
  const accessToken = localStorage.getItem('accesstoken');
  axios.interceptors.request.use((config) => {
    config.headers.accesstoken = accessToken;
    return config;
  });

  let user = localStorage.getItem('user')
  if (!user && (to.path.indexOf('/Login') < 0)) {
    return next({ path: '/Login' });
  } else if (user && (to.path.indexOf('/news') < 0) && to.path != '/edit_news') {
    return next({ path: '/news' });
  }
  return next();
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');