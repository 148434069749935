import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            path: '/Login',
            name: 'Login',
            component: () => import('../components/Login.vue')
        },
        {
            path: '/news',
            name: 'News',
            component: () => import('../components/News.vue')
        },
        {
            path: '/edit_news',
            name: 'Edit News',
            component: () => import('../components/EditNews.vue')
        },
        {
            path: '/news_letter',
            name: 'News Letter',
            component: () => import('../components/NewsLetter.vue')
        }
    ]
});